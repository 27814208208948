import React, { useState } from "react";
import "./App.css"; // Make sure to import the CSS file for styling
import "./styles.css";
import logo from './assets/logo.png';
import funded from './assets/co_funded.png';

// Simple question and answer pairs
const qaPairs = [
  { question: "What are the EU Textile Standards?",
    answer: "EU Textile Standards are regulations that deal with environmental concerns and are meant to lead to a more sustainable fashion. For example, Extended Producer Responsibility on Textiles (ERP), Corporate Sustainability Due Diligence Directive (CSDD), Corporate Sustainability Reporting Directive (CSRD), Eco-Design for Sustainable Products Regulation (ESPR)"
  },
  { question: "Pote ton pairneis pote ton tros?",
    answer: "Einai ligos o misthos"
  },
  { question: "What are the EU Textile Standards bla?",
    answer: "EU Textile Standards are regulations that deal with environmental concerns and are meant to lead to a more sustainable fashion. For example, Extended Producer Responsibility on Textiles (ERP), Corporate Sustainability Due Diligence Directive (CSDD), Corporate Sustainability Reporting Directive (CSRD), Eco-Design for Sustainable Products Regulation (ESPR)"
  },
  { question: "What are the EU Textile Standards blu?",
    answer: "EU Textile Standards are regulations that deal with environmental concerns and are meant to lead to a more sustainable fashion. For example, Extended Producer Responsibility on Textiles (ERP), Corporate Sustainability Due Diligence Directive (CSDD), Corporate Sustainability Reporting Directive (CSRD), Eco-Design for Sustainable Products Regulation (ESPR)"
  },
  { question: "What are the EU Textile Standards bla blu?",
    answer: "EU Textile Standards are regulations that deal with environmental concerns and are meant to lead to a more sustainable fashion. For example, Extended Producer Responsibility on Textiles (ERP), Corporate Sustainability Due Diligence Directive (CSDD), Corporate Sustainability Reporting Directive (CSRD), Eco-Design for Sustainable Products Regulation (ESPR)"
  },
  { question: "Why is it important to create a personalized offer in e-commerce?",
    answer: "It is important because a personalized offer not only improves the customer experience, but also increases the conversion rate, which leads to higher sales."
  },
  {
    question: "Who should use the Digital Product Passports?",
    answer: "Any fashion company or business should adopt the Digital Product Passports, in order to keep track of fashion products throughout their lifecycle and facilitate recycling and reuse, and to pave the way for a real circular economy in the fashion sector. For example, two German sustainable fashion companies, Hu le Kes and Armedangels, apply DPPs and RFID to their products, thus minimizing the use of new resources and reducing waste and emissions."
  },
  { question: "Who is responsible for AI regulation?",
    answer: "AI regulation and policy is a problem all over the world, including the European Union and supra-national bodies like OECD.In fact, as part of its digital strategy, the EU wants to regulate AI  to ensure better conditions for its development and use, ensuring that AI systems in the EU are safe, transparent, traceable, non-discriminatory and respectful of the environment."
  },
  { 
    question: "Are you the IG-Fashion Chatbot?",
    answer: "Yes I am! Happy to assist you in any question you have!"
  },
];

// Tokenize the text by converting to lowercase, removing non-word characters, and splitting into words
const excludeWords = ["is", "it", "the", "be", "for", "who", "what", "why", "when", "where", "how"];

const tokenize = (text) => {
  return text
    .toLowerCase()
    .split(/\W+/)
    .filter((word) => word && !excludeWords.includes(word));
};

// Option tree structure for the second chatbot interface
const optionTree = {
  LEGAL: {
    message: "Please select the sub-section in which you are interested.",
    options: {
      "1.1 Introduction": {
        message: "Great! Here are all the questions related to the 1.1 Introduction section",
        options: {
          "Who needs to be informed about EU fashion regulations?": "All fashion stakeholders: designers, producers, businesses, retailers, but also consumers.",
          "Why do stakeholders need to be informed about EU fashion regulations?": "To ensure that regulations are respected and encourage a sustainable approach in fashion.",
         
        },
      },
      "1.2 New Textile Standards in the EU": {
        message: "Here are all the questions related to the 1.2 New Textile Standards section",
        options: {
          "Who should apply EU textile standards?": "All fashion companies operating within the EU should comply with the EU Textile Standards.",
          "What are the EU Textile Standards?": "These standards cover product quality, sustainability, and ethical practices.",
        
        },
      },
      
    },
  },
  "F&T PRODUCTION": {
    message: "Here are the options for F&T Production.",
    options: {
      "What is F&T Production?": "F&T production refers to Fashion and Textile production processes.",
     
    },
  },
  DESIGN: {
    message: "Here are the options for Design.",
    options: {
      "What is sustainable fashion design?": "Sustainable fashion design focuses on creating clothing with minimal environmental impact.",
      
    },
  },
  TEXTILES: {
    message: "Here are the options for Textiles.",
    options: {
      "What are sustainable textiles?": "Sustainable textiles are made from eco-friendly, biodegradable, or recycled materials.",
      
    },
  },
};

const App = () => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [showChatbot, setShowChatbot] = useState(false);  // State to control chatbot visibility
  const [showSecondOption, setShowSecondOption] = useState(false);  // State to control the second option view
  const [currentSection, setCurrentSection] = useState(null);  // Tracks the current section for the second option

  // Συνάρτηση που χειρίζεται το "Back"
  const handleBack = () => {
    if (showSecondOption && currentSection) {
      setCurrentSection(null); // Πηγαίνει πίσω στο αρχικό επίπεδο επιλογών
    } else if (showSecondOption) {
      setShowSecondOption(false); // Επιστροφή στην αρχική οθόνη
    } else if (showChatbot) {
      setShowChatbot(false); // Επιστροφή στην αρχική οθόνη
    }
  };
  // Find the best matching question
  const findBestMatch = (input) => {
    const inputTokens = tokenize(input); // Εξαιρούμε τις λέξεις από το input
    let bestMatch = null;
    let maxMatches = 0;
  
    qaPairs.forEach(({ question, answer }) => {
      const questionTokens = tokenize(question); // Εξαιρούμε τις λέξεις από το question
      const matches = inputTokens.filter(token => questionTokens.includes(token)).length;
  
      // Ενημέρωση καλύτερης αντιστοιχίας
      if (matches > maxMatches) {
        maxMatches = matches;
        bestMatch = answer;
      }
    });
  
    return bestMatch || "Sorry, I don't understand that question.";
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (userInput.trim() === "") return;  // Avoid empty submissions

  //   const userMessage = { sender: "user", text: userInput };
  //   const botResponse = findBestMatch(userInput);
  //   const botMessage = { sender: "bot", text: botResponse };

  //   // Update chat history with both user question and bot answer
  //   setChatHistory([...chatHistory, userMessage, botMessage]);
  //   setUserInput("");  // Clear input after submission
  // };

  // Συνάρτηση που χειρίζεται την υποβολή της ερώτησης του χρήστη
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.trim() === "") return;
  
    const words = userInput.trim().split(/\s+/);
    if (words.length < 3) {
      alert("Παρακαλώ, γράψτε μια ερώτηση με τουλάχιστον 3 λέξεις.");
      return;
    }
  
    const userMessage = { sender: "user", text: userInput };
    setChatHistory([...chatHistory, userMessage]);
  
    // Προσθήκη της ένδειξης πληκτρολόγησης στο ιστορικό συνομιλίας
    const typingMessage = {
      sender: "bot",
      text: (
        <div style={{ display: "flex", alignItems: "center" }}>
         
          <div id="three_dots_loading" className="typing-indicator">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      ),
    };
    setChatHistory((prevChatHistory) => [...prevChatHistory, typingMessage]);
    setUserInput(""); // Καθαρισμός της εισαγωγής
  
    // Καθυστέρηση 3 δευτερολέπτων πριν την απάντηση του bot
    setTimeout(() => {
      const botResponse = findBestMatch(userMessage.text);
      const botMessage = { sender: "bot", text: botResponse };
  
      // Αντικατάσταση της ένδειξης πληκτρολόγησης με την πραγματική απάντηση
      setChatHistory((prevChatHistory) => {
        const updatedChatHistory = [...prevChatHistory];
        updatedChatHistory[updatedChatHistory.length - 1] = botMessage;
        return updatedChatHistory;
      });
    }, 2000); // 2 δευτερόλεπτα καθυστέρηση
  };
  


  // Handles option clicks in the second chatbot view
  const handleOptionClick = (option) => {
    if (option === "Back") {
      setCurrentSection(null);  // Go back to the main options
    } else {
      setCurrentSection(option);  // Set the new current section
    }
  };

  // Renders the options for the current section in the second chatbot
  const renderOptions = () => {
    const section = optionTree[currentSection];  // Fetch the section from the tree
    if (!section) return null;  // If no section is selected, do nothing

    return (
      <div>
        <div className="message bot">{section.message}</div>
        <div className="options">
          {Object.keys(section.options).map((option, index) => (
            <button
              key={index}
              className="option-button"
              onClick={() => handleOptionClick(option)}
              disabled={section.options[option] === null} 
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    );
  };

  // Displays the content or options dynamically based on the current section
  const renderContentOrOptions = () => {
    if (!currentSection) {
      return (
        <div className="options">
          <button className="option-button" onClick={() => handleOptionClick("LEGAL")}>LEGAL</button>
          <button className="option-button" onClick={() => handleOptionClick("F&T PRODUCTION")}>F&T PRODUCTION</button>
          <button className="option-button" onClick={() => handleOptionClick("DESIGN")}>DESIGN</button>
          <button className="option-button" onClick={() => handleOptionClick("TEXTILES")}>TEXTILES</button>
        </div>
      );
    } else if (optionTree[currentSection]?.options) {
      return renderOptions();  // Render next level options
    } 
    // else {
    //   return (
    //     <div className="message bot">
    //       {/* Just show a Back button if there are no further options */}
    //       <button className="option-button" onClick={() => setCurrentSection(null)}>Back</button>
    //     </div>
    //   );
    // }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      {/* Header Section */}
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>IG-Fashion<br />Chatbot</h1>
        <img src={funded} alt="Co-funded by the EU" className="eu-flag" />
      </div>

      {/* Initial buttons */}
      {!showChatbot && !showSecondOption && (
        <div className="body" style={{display:"flex", flexDirection:"column", textAlign:"center"}}>
          <p style={{fontSize: "22px"}}>Welcome choose your chatbot experience!</p>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button id = "initial_button_left" 
              onClick={() => setShowChatbot(true)}
            >
              Ask a question!
            </button>
            <button id = "initial_button_right"
              onClick={() => setShowSecondOption(true)}  // Show second option and load script.js
            >
              Select a sector!
            </button>
          </div>
        </div>
      )}

      {/* Chatbot UI (only shown if showChatbot is true) */}
      {showChatbot && (
        <div className="body">
          <h1>IG Fashion Chatbot</h1>

         {/* Ιστορικό συνομιλίας */}
         {chatHistory.length > 0 && (
            <div className="chat-history" style={{ overflowY: "auto", marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
              {chatHistory.map((message, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: message.sender === "user" ? "right" : "left",
                    margin: "10px 0",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "10px",
                      backgroundColor: message.sender === "user" ? "#0084ff" : "#e0e0e0",
                      maxWidth: "80%",
                      wordWrap: "break-word",
                    }}
                  >
                    <strong>{message.sender === "user" ? "You" : "Chatbot"}:</strong> {message.text}
                  </div>
                </div>
              ))}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <input
              type="text" id="ask_a_question"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Ask a question..."
              style={{ width: "90%", padding: "10px", fontSize: "16px" }}
            />
            <button id="ask_button" type="submit">
              Ask
            </button>
             {/* Κουμπί Back στο κάτω αριστερό μέρος */}
             
            <div>
              <button id="back_to_start" onClick={() => setShowChatbot(false)} >Go Back</button>
            </div>
          </form>
        </div>
      )}

      {/* Second option view (only shown if showSecondOption is true) */}
      {showSecondOption && (
        <div className="body" id="chatbox">
          {renderContentOrOptions()}  {/* Render content dynamically */}
          {/* Κουμπί Back στο κάτω αριστερό μέρος */}
          <div>
            <button id="back_to_start" onClick={handleBack} >Back</button>
          </div>
        </div>
      )}


      

      {/* Footer */}
      <div id="petros" className="footer">
        Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.
      </div>
    </div>
  );
};

export default App;
